import React from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <div className="footer_navbar">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="grid">
                <div></div>
                <div>
                  <NavLink to={"work"}>Work</NavLink>
                </div>
                <div></div>
                <div>
                  <NavLink to={"practise"}>Practice</NavLink>
                </div>
                <div></div>
                <div>
                  <NavLink to={"contact"}>Contact</NavLink>
                </div>
                <div></div>
                <div></div>
                <div></div>
                <div>
                  <NavLink to={"people"}>People</NavLink>
                </div>
                <div></div>
                <div>
                  <NavLink to={"Colleagues"}>Future Colleague</NavLink>
                </div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
