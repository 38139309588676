import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import PlaceholderImage from "./../../assets/images/loading_page.webp";

const WorkContent = ({ categoryData, base_Url }) => {

  if (!Array.isArray(categoryData)) {
    return <p>No data available.</p>;
  }
  // Sort the categoryData by 'sort_order' in ascending order
  const sortedCategoryData = [...categoryData].sort(
    (a, b) => a.sort_order - b.sort_order
  );

  

  return (
    <>
      <div className="work-scroll">
        <div className="row">
          {sortedCategoryData.map((item) => {
            // Parse the project_images string into an array
            const cleanImagesString = item.project_images
              .replace(/,\s*$/, '') // Remove trailing comma
              .replace(/'/g, '"');   // Replace single quotes with double quotes for valid JSON

            // Parse the project_images string into an array
            let projectImages = [];
            try {
              projectImages = JSON.parse(cleanImagesString);
            } catch (error) {
              console.error("Error parsing project_images:", error);
            }

            // Get the first image from the parsed array or fallback to the placeholder image
            const firstImage = projectImages.length > 0 ? projectImages[0] : PlaceholderImage;

            return(
            <div className={`col-lg-3 mb-4 work-item cat-${item.cat_id}`} key={item.id}>
              <Link to={`/work-details/${item.id}`}>
                <div className="tabs-content">
                  <LazyLoadImage
                    src={`${base_Url}/images/${firstImage}`}
                    placeholderSrc={PlaceholderImage}
                    alt={item.name}
                    effect="blur"
                    loading="lazy"
                    className="img-fluid"
                    wrapperProps={{
                      // If you need to, you can tweak the effect transition using the wrapper style.
                      style: { transitionDelay: "1s" }
                    }}
                  />
                </div>
                <div className="content">
                  <p>{item.name}</p>
                </div>
              </Link>
            </div>
          )})}
        </div>
      </div>
    </>
  );
};

export default WorkContent;
