import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import ReactPlayer from "react-player";
import { Navigation, Pagination } from "swiper";
import Preloader from "../components/common/Preloader";
import PlaceholderImage from "./../assets/images/loading_page.webp";

const WorkDetails = ({ props, localData }) => {
  const params = useParams();
  const base_Url = process.env.REACT_APP_BASE_URL;
  const [sectionBg, setSectionBg] = useState({});
  const [category, setCategory] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    if (
      localData &&
      localData.home_banners &&
      localData.home_banners.length > 0
    ) {
      setSectionBg(localData);
      setLoading(false);
    }
  }, [localData]);

  useEffect(() => {
    async function getData() {
      const allWordData = JSON.parse(localStorage.getItem("all-work"));

      setCategory(allWordData);
    }
    getData();
  }, [localData]);

  let singleCategory =
    category.length > 0 &&
    category.find((item) => {
      return item.id == params.id;
    });

  useEffect(() => {
    if (
      singleCategory &&
      singleCategory.project_images &&
      singleCategory.project_images.length > 0
    ) {
      const cleanedString = singleCategory.project_images.replace(
        /[\[\]"']/g,""
      );
      const imageData = cleanedString.split(",");
      // console.log(imageData);
      setData(imageData);
      setLoading(false);
    }
  }, [localData, singleCategory]);


  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div
          className="work_details"
          style={{
            backgroundImage: `url(${base_Url}/images/${sectionBg.work_bg_image})`
          }}
        >
          <div className="container">
            <div className="work-scroll">
              <div className="row">
                <div className="col-lg-3 col-12 order-2 order-lg-1">
                  <div className="work_details_left">
                    <div className="work_details_content">
                      <h4>{singleCategory.name}</h4>
                    </div>
                    <div className="work_details_content">
                      <ul style={{ listStyle: "none", paddingLeft: "0px" }}>
                        {singleCategory.description
                          ? singleCategory.description
                              .split("|")
                              .map((item) => (
                                <li key={item.id}>
                                  <p>{item}</p>
                                </li>
                              ))
                          : "Content comming soon"}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-12 order-1 order-lg-2">
                  <div className="col-12">
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={8}
                      navigation={true}
                      pagination={{
                        clickable: true,
                        dynamicBullets: true
                      }}
                      modules={[Pagination, Navigation]}
                      className="mySwiper"
                      breakpoints={{
                        640: {
                          slidesPerView: 1
                        },
                        768: {
                          slidesPerView: 1
                        },
                        1024: {
                          slidesPerView: 1.1
                        }
                      }}
                    >
                      {data.length > 0 && data?.map((item, index) => (
                        <SwiperSlide key={index}>
                          <div className="work_gellary">
                            <LazyLoadImage
                              effect="blur"
                              loading="lazy"
                              src={`${base_Url}/images/${item}`}
                              placeholderSrc={PlaceholderImage}
                              alt={item}
                              className="img-fluid work_gellary_item"
                              wrapperProps={{
                                // If you need to, you can tweak the effect transition using the wrapper style.
                                style: { transitionDelay: "1s" }
                              }}
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                      {singleCategory.video !== null ? (
                        <SwiperSlide>
                          <ReactPlayer
                            url={`${singleCategory.video}`}
                            controls={true}
                            width={"100%"}
                            height={480}
                            // onPlay={() => console.log("video is playing")}
                            // onPause={() => console.log("video is paused")}
                          />
                        </SwiperSlide>
                      ) : (
                        ""
                      )}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WorkDetails;
