import Isotope from "isotope-layout";
import React, { useEffect, useRef, useState } from "react";
import Preloader from "../components/common/Preloader";
import WorkContent from "../components/work/WorkContent";
import WorkTabs from "../components/work/WorkTabs";
import "./../assets/styles/isotop.css";


const Work = ({localData}) => {
  const base_Url = process.env.REACT_APP_BASE_URL;
  const [sectionBg, setSectionBg] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [dataCat, setDataCat] = useState([]);
  const [filter, setFilter] = useState("all");
  const [loading, setLoading] = useState(true);
  const isotopeInstance = useRef(null);
  const workContentRef = useRef(null);


  useEffect(() => {
    if (localData && localData.home_banners && localData.home_banners.length > 0) {
      setSectionBg(localData);
      setLoading(false);
    }
  }, [localData]);

  useEffect(() => {
    if (localData && localData.project_images && localData.project_images.length > 0) {
      const cleanedString = localData.project_images.replace(/[\[\]"]/g, '');
      const imageData = cleanedString.split(',');
      // console.log(imageData);
      setCategoryData(imageData);
      setLoading(false);
    }
  }, [localData]);
  


  useEffect(() => {
    async function fetchData() {
      try {
        const allWordData = JSON.parse(localStorage.getItem("all-work"));
        const allWordCatData = JSON.parse(localStorage.getItem("all-work-cat"));

        setCategoryData(allWordData);
        setDataCat(allWordCatData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, [base_Url, localData]); 


  useEffect(() => {
    if (categoryData.length > 0 && workContentRef.current) {
      isotopeInstance.current = new Isotope(workContentRef.current, {
        itemSelector: ".work-item",
        layoutMode: "fitRows",
      });
    }
    // Cleanup Isotope on component unmount
    return () => {
      if (isotopeInstance.current) {
        isotopeInstance.current.destroy();
      }
    };
  }, [categoryData]);

  const handleTabChange = (filterValue) => {
    if (isotopeInstance.current) {
      isotopeInstance.current.arrange({ filter: filterValue });
    }
  };
  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div
          className="work"
          style={{
            backgroundImage: `url(${base_Url}/images/${sectionBg.work_bg_image})`
          }}
        >
          <div className="container">
            <div className="practise-scroll">
              <div className="row">
                <div className="col-lg-3">
                  <WorkTabs tabs={dataCat} onTabChange={handleTabChange} />
                </div>
                <div className="col-lg-9">
                  <div ref={workContentRef} className="work-content">
                    <WorkContent categoryData={categoryData} base_Url={base_Url} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Work;
