import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import Preloader from "../components/common/Preloader";
import PlaceholderImage from "./../assets/images/loading_page.webp";

const Home = ({ localData }) => {
  const base_Url = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  
  useEffect(() => {
    if (localData && localData.home_banners && localData.home_banners.length > 0) {
      const cleanedString = localData.home_banners.replace(/[\[\]"]/g, '');
      const imageData = cleanedString.split(',');
      // console.log(imageData);
      setData(imageData);
      setLoading(false);
    }
  }, [localData]);


  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="home-slider">
          <Swiper
            loop={true}
            speed={1000}
            parallax={true}
            autoplay={{
              delay: 6500,
              disableOnInteraction: false
            }}
            watchSlidesProgress={true}
            pagination={{
              clickable: true,
              dynamicBullets: true
            }}
            navigation={true}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
          >
            {data.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="sliderItem">
                    <LazyLoadImage
                      effect="blur"
                      loading="lazy"
                      src={`${base_Url}/images/${item}`}
                      placeholderSrc={PlaceholderImage}
                      alt={item}
                      className="img-fluid work_gellary_item"
                      wrapperProps={{
                        style: { transitionDelay: "1s" }
                      }}
                    />
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      )}
    </>
  );
};

export default Home;
