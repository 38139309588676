import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import React, { useEffect, useState } from "react";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import "./assets/styles/isotop.css";
import Layout from "./components/Layout/Layout";
import Colleagues from "./pages/Colleagues";
import ColleaguesDetails from "./pages/ColleaguesDetails";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import People from "./pages/People";
import Practice from "./pages/Practice";
import Work from "./pages/Work";
import WorkDetails from "./pages/WorkDetails";

function App() {
  const base_Url = process.env.REACT_APP_BASE_URL;
  const [data, setData] = useState([]);

  useEffect(() => {
    const api = axios.create({
      baseURL: base_Url,
      withCredentials: true
    });

    async function fetchAdminInfo() {
      try {
        let adminInfoData;
        const adminInfo = await api.get("api/basic-fe");
        localStorage.setItem("admin-info", JSON.stringify(adminInfo.data));
        const localAdminInfo = localStorage.getItem("admin-info");

        if (localAdminInfo && localAdminInfo !== "undefined") {
          adminInfoData = JSON.parse(localAdminInfo);
          // console.log(localAdminInfo);
          setData(adminInfoData[0]);
        }
        return true; // Indicate that admin info is fetched
      } catch (error) {
        console.error("Error fetching admin info:", error);

        return false; // Indicate that fetching admin info failed
      }
    }

    async function fetchOtherData() {
      try {
        const [
          catResponse,
          wcatResponse,
          pcatResponse,
          peopleResponse,
          aboutResponse,
          officeResponse,
          historyResponse,
          colleaguesResponse
        ] = await Promise.all([
          api.get("api/project-fe"),
          api.get("api/w-cat"),
          api.get("api/p-cat"),
          api.get("api/people-fe"),
          api.get("api/about-fe"),
          api.get("api/office-fe"),
          api.get("api/history-fe"),
          api.get("api/colleagues-fe")
        ]);

        // Store other data in localStorage
        localStorage.setItem("all-work", JSON.stringify(catResponse.data));
        localStorage.setItem("all-work-cat", JSON.stringify(wcatResponse.data));
        localStorage.setItem(
          "all-people-cat",
          JSON.stringify(pcatResponse.data)
        );
        localStorage.setItem(
          "all-people-data",
          JSON.stringify(peopleResponse.data)
        );
        localStorage.setItem("about-data", JSON.stringify(aboutResponse.data));
        localStorage.setItem(
          "office-data",
          JSON.stringify(officeResponse.data)
        );
        localStorage.setItem(
          "history-data",
          JSON.stringify(historyResponse.data)
        );
        localStorage.setItem(
          "colleagues-data",
          JSON.stringify(colleaguesResponse.data)
        );
      } catch (error) {
        console.error("Error fetching other data:", error);
      }
    }

    async function fetchData() {
      const adminInfoFetched = await fetchAdminInfo();
      if (adminInfoFetched) {
        await fetchOtherData();
      }
    }

    fetchData();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout localData={data}/>}>
            <Route index element={<Home localData={data}/>} />
            <Route path="work" element={<Work localData={data}/>} />
            <Route path="/work-details/:id" element={<WorkDetails localData={data}/>} />
            <Route path="people" element={<People localData={data}/>} />
            <Route path="practise" element={<Practice localData={data}/>} />
            <Route path="Colleagues" element={<Colleagues localData={data}/>} />
            <Route
              path="/collegues-details/:id"
              element={<ColleaguesDetails localData={data}/>}
            />
            <Route path="Contact" element={<Contact localData={data}/>} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
